import React from 'react';
import xefiArrowLeft from '@src/assets/img/mobileapp/xefiArrowLeft.png';
import xefiArrowRight from '@src/assets/img/mobileapp/xefiArrowRight.png';

import { QRCodeSVG } from 'qrcode.react';

import androidQrCode from '@src/assets/img/mobileapp/android.inline.png';
import appleQrCode from '@src/assets/img/mobileapp/apple.inline.png';

/* keep coment on traduction key in order to put them in the future */
function MobilDownload({ links, appPicture }) {
  return (
    <section className='min-h-screen-custom pl-16 pr-2 py-6'>
      <h1 className='font-lato font-bold text-xl leading-6'>
        {/*  {t('application.app')} */}Application mobile
      </h1>
      <div className='flex flex-col lg:!flex-row items-center gap-4 lg:!gap-[10vw]'>
        <div className='relative w-64'>
          <img
            src={xefiArrowRight}
            alt={'xefi arrow right'}
            width={140}
            height={50}
            className='absolute -right-20 top-20 z-20 '
          />
          <img
            src={xefiArrowLeft}
            alt={'xefi arrow left'}
            width={80}
            height={50}
            className='absolute -left-10 bottom-20 z-10'
          />
          <img
            src={appPicture}
            alt={'phone'}
            width={253}
            height={573}
            className='min-w-[200px] relative z-20'
          />
        </div>
        <div className='py-6 px-10 border-solid border border-[#F2F2F2] max-w-[700px] w-full rounded-lg'>
          <div className='flex flex-col gap-[.7rem]'>
            <h2 className='text-2xl font-lato text-center text-[#2F2F2F] font-bold'>
              {/*  {t('application.download.title')} */}Télécharger notre
              application
            </h2>
            <p className='text-center font-lato text-xl text-[#2F2F2F]'>
              {/*  {t('application.download.description')} */}Facilitez la prise
              de congés et le paiement des collaborateurs avec notre
              fonctionnalité d’export dans les logiciels RH.
            </p>
            <p className='text-center font-lato text-sm text-[#8D8D8D]'>
              {/* {t('application.download.note')} */}Pour télécharger
              l’application, scanner ce QR code avec votre téléphone
            </p>
          </div>
          <div className='flex justify-center mt-10'>
            <div className='flex items-start gap-4'>
              <div className='flex flex-col gap-4 items-center'>
                <img
                  src={androidQrCode}
                  alt={'android qr code'}
                  className='w-[100px]'
                />
                <QRCodeSVG
                  style={{
                    height: 'auto',
                    maxWidth: '100%',
                    width: '72%',
                  }}
                  value={links.android}
                  fgColor='#E20917'
                />
              </div>
              <div className='flex flex-col gap-4 items-center'>
                <img
                  src={appleQrCode}
                  alt={'apple qr code'}
                  className='w-[100px]'
                />
                <QRCodeSVG
                  style={{
                    height: 'auto',
                    maxWidth: '100%',
                    width: '72%',
                  }}
                  value={links.apple}
                  fgColor='#E20917'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobilDownload;
