import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import authProviderService from '@data/authProviderService';
import SkeletonMobileApp from '@components/Skeletons/SkeletonMobileApp';
import MobilDownload from '@src/components/shared/mobilDownload';
import appPicture from '@src/assets/img/mobileapp/phoneDownload.png';
const Download = () => {
  const { t } = useTranslation();
  const [links, setLinks] = useState({ android: '', apple: '' });
  const [isLoading, setIsLoading] = useState(true);

  const requestBody = {
    search: {
      filters: [
        {
          field: 'id',
          operator: '=',
          value: process.env.GATSBY_APP_APP_UUID,
        },
      ],
    },
  };

  const fetchLinks = () => {
    setIsLoading(true);
    authProviderService
      .post('/applications/search', requestBody)
      .then((resp) => {
        const res = resp.data.data[0];
        setLinks({ android: res.android_link, apple: res.apple_link });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  return (
    <div className='min-h-screen-custom'>
      {!isLoading ? (
        <MobilDownload links={links} appPicture={appPicture} />
      ) : (
        <SkeletonMobileApp />
      )}
    </div>
  );
};

export default Download;
