import React from 'react';
import { Skeleton } from '@mui/material';

const SkeletonMobileApp = () => {
  return (
    <div className='flex flex-col  items-center justify-around gap-y-6 lg:!flex-row lg:gap-16 mt-40'>
      <div className=' drop-shadow-card rounded-lg w-[300px] flex flex-col justify-between items-center'>
        <Skeleton
          variant='rectangular'
          width='100%'
          height={500}
          sx={{ borderRadius: 8 }}
        />
      </div>
      <div className=' drop-shadow-card rounded-lg w-[675px] flex flex-col justify-between items-center'>
        <div className='w-full  relative'>
          <Skeleton
            animation='wave'
            variant='rectangular'
            height={380}
            className='dark:bg-[#6b6b6b] max-w-[675px] w-full rounded-lg'
          />
        </div>
        <div className='absolute flex flex-col m-4'>
          <div className='w-[500px] h-12 mb-4 flex flex-col items-center'>
            <Skeleton
              animation='wave'
              variant='text'
              height={'100%'}
              className='dark:bg-[#292828] w-72'
            />
          </div>
          <div className='w-[500px] h-12 mb-4 flex flex-col items-center'>
            <Skeleton
              animation='wave'
              variant='text'
              height={'100%'}
              className='dark:bg-[#292828] w-full'
            />

            <Skeleton
              animation='wave'
              variant='text'
              height={'100%'}
              className='dark:bg-[#292828] w-72'
            />
          </div>
          <div className='w-[500px] h-6 mb-8 flex flex-col items-center'>
            <Skeleton
              animation='wave'
              variant='text'
              height={'100%'}
              className='dark:bg-[#292828] w-96'
            />
          </div>
          <div className='flex justify-center gap-20'>
            <div className='mb-3 text-center flex flex-col items-center'>
              <div className='w-24 h-12'>
                <Skeleton
                  animation='wave'
                  variant='text'
                  height={'100%'}
                  className='dark:bg-[#292828] w-full rounded-lg'
                />
              </div>
              <div className='h-24 w-24 mb-2'>
                <Skeleton
                  animation='wave'
                  variant='rectangular'
                  height={'100%'}
                  className='dark:bg-[#292828] w-full rounded-lg'
                />
              </div>
            </div>

            <div className='mb-3 text-center flex flex-col items-center'>
              <div className='w-24 h-12'>
                <Skeleton
                  animation='wave'
                  variant='text'
                  height={'100%'}
                  className='dark:bg-[#292828] w-full rounded-lg'
                />
              </div>
              <div className='h-24 w-24 mb-2'>
                <Skeleton
                  animation='wave'
                  variant='rectangular'
                  height={'100%'}
                  className='dark:bg-[#292828] w-full rounded-lg'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonMobileApp;
